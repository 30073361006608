import {api} from "@/main";
import axios from "axios";
import {counter} from "@fortawesome/fontawesome-svg-core";

export const loadItems = async ({state, commit}, {table_id}) => {
    // const url = api + '/items' // Table' + '/' + table_id
    //console.log(table_id)
    const url = api + (!table_id ? '/items' : '/itemsTable' + '/' + table_id)
    //console.log(url)
    state.isLoading = true

    try {
        const res = await axios.get(url)
        const items = res.data.items
        items.sort(function (a, b) {
            const aSize = a.price;
            const bSize = b.price;
            const aLow = a.name;
            const bLow = b.name;
            if (aSize === bSize) {
                return (aLow < bLow) ? -1 : (aLow > bLow) ? 1 : 0;
            } else {
                return (aSize < bSize) ? -1 : 1;
            }
        });
        console.log(res.data.items)
        commit('setItems', res.data.items)
        //state.isLoading = false
    } catch (e) {
        commit('setItems', [])
    }
}

export const loadCategories = async ({state, commit}, {table_id}) => {
    // const url = api + '/categories'
    // state.isLoading =
    let tid = parseInt(table_id)
    const url = api + (!tid || tid === 0 ? '/categories' : '/categories_table' + '/' + table_id)

    try {
        const res = await axios.get(url)
        commit('setCategories', res.data.categories)
        //state.isLoading = false
    } catch (e) {
        commit('setCategories', [])
    }
}

export const loadRecommends = async ({commit}, {table_id}) => {
    //const url = api + '/recommends'

    let tid = parseInt(table_id)
    const url = api + (!tid || tid === 0 ? '/recommends' : '/recommends_table' + '/' + table_id)

    try {
        const res = await axios.get(url)
        commit('setRecommends', res.data.items)
    } catch (e) {
        commit('setRecommends', [])
    }
}


export const loadItem = async ({commit}, item_id) => {
    const url = api + '/item/' + item_id
    try {
        const res = await axios.get(url)
        const item = res.data.item
        commit('setItem', item)
        return item

    } catch (e) {
        commit('setItem', {})
    }
}

export const loadRecommendsId = async ({commit}, item_id) => {
    const url = api + '/recommends/' + item_id

    try {
        const res = await axios.get(url)
        const item = res.data.items
        commit('setRecommends', res.data.items)
        return item

    } catch (e) {
        commit('setRecommends', {})
    }
}


export const loadRestaurant = async ({commit}) => {
    const url = api + '/restaurant' + '/0'

    try {
        const res = await axios.get(url)
        commit('setRestaurant', res.data.restaurant)
    } catch (e) {
        commit('setRestaurant', [])
    }
}
